import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f5f5f5',
  },
  '&:nth-of-type(even)': {
    backgroundColor: 'white',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const TableCellHead = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#1a52a1',
  color: 'white',
  textAlign: 'right',
  fontWeight: 'bold',
  left: {
    textAlign: 'left',
  },
  borderBottom: '3px solid #fdc811',
}));

//  This will set a dynamic height on the table
const useStyles = makeStyles({
  customTableContainer: {
    overflowX: 'initial',
  },
});

const ToLines = (props) => {
  // console.log('RENDERING: TO Lines');
  const { toLines, origToLines } = props.props;
  const classes = useStyles();

  return (
    <div id='toLines'>
      <div
        style={{
          width: '100%',
          backgroundColor: '#1a52a1',
          color: '#fff',
          textAlign: 'center',
          fontWeight: 'bold',
          borderBottom: '2px dotted #869fb3',
        }}
      >
        Incoming Items
        {toLines.length < origToLines.length
          ? ` (${toLines.length} of ${origToLines.length})`
          : ` (${toLines.length})`}
      </div>
      <TableContainer classes={{ root: classes.customTableContainer }}>
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
              <TableCellHead
                style={{
                  textAlign: 'center',
                }}
              >
                TO #
              </TableCellHead>
              <TableCellHead
                style={{
                  textAlign: 'center',
                }}
              >
                SWA #
              </TableCellHead>
              <TableCellHead
                style={{
                  textAlign: 'left',
                }}
              >
                Your Part #
              </TableCellHead>
              <TableCellHead
                style={{
                  textAlign: 'left',
                }}
              >
                Description
              </TableCellHead>
              <TableCellHead>QTY Remaining</TableCellHead>
              <TableCellHead>QTY Shipped</TableCellHead>
              <TableCellHead>QTY Backordered</TableCellHead>
              <TableCellHead
                style={{
                  textAlign: 'center',
                }}
              >
                Expected Ship Date
              </TableCellHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {toLines.map((row) => (
              <StyledTableRow
                key={row.itemNo}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell component='th' scope='row' align='center'>
                  {row.toNum}
                </StyledTableCell>
                <StyledTableCell component='th' scope='row' align='center'>
                  {row.itemNo}
                </StyledTableCell>
                <StyledTableCell align='left'>{row.custPart}</StyledTableCell>
                <StyledTableCell align='left'>{row.itemDesc}</StyledTableCell>
                <StyledTableCell align='right'>
                  {row.qtyRemaining
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </StyledTableCell>
                <StyledTableCell align='right'>
                  {row.qtyShipped
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </StyledTableCell>
                <StyledTableCell align='right'>
                  {row.qtyBackordered
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </StyledTableCell>
                <StyledTableCell align='center'>{row.shipDate}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {toLines.length === 0 ? (
        <div
          style={{
            textAlign: 'center',
            color: 'red',
            fontWeight: 'bold',
            background: '#FFF',
          }}
        >
          No Results to Display
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
// ToLines.whyDidYouRender = true;
export default ToLines;
