import React from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const DataControlsRow = (props) => {
  // console.log('RENDERING: Data Controls Row');
  const {
    currView,
    setCurrView,
    origContractDetails,
    setContractDetails,
    origToLines,
    setToLines,
  } = props.props;

  const filterResults = (filterValue, currView) => {
    if (!filterValue) {
      console.log('is blank with currView of: ' + currView);
      if (currView === 'contractDetails') {
        console.log('setting itemData to original');
        setContractDetails(origContractDetails);
      } else if (currView === 'toLines') {
        setToLines(origToLines);
      }
    } else {
      if (currView === 'contractDetails') {
        let filteredArray = origContractDetails.filter((item) => {
          return (
            item.itemDesc.toUpperCase().includes(filterValue.toUpperCase()) ||
            item.custPart.toUpperCase().includes(filterValue.toUpperCase()) ||
            item.swaItemNo.toUpperCase().includes(filterValue.toUpperCase())
          );
        });

        setContractDetails(filteredArray);
      } else if (currView === 'toLines') {
        let filteredArray = origToLines.filter((item) => {
          return (
            item.itemDesc.toUpperCase().includes(filterValue.toUpperCase()) ||
            item.custPart.toUpperCase().includes(filterValue.toUpperCase()) ||
            item.itemNo.toUpperCase().includes(filterValue.toUpperCase())
          );
        });

        setToLines(filteredArray);
      }
    }
  };

  return (
    <div id='controls'>
      {/*BUTTON ROW */}

      <div
        id='buttonRow'
        className='buttonRow'
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {currView === 'contractDetails' ? (
          <Button
            className='btn btn-warning'
            style={{ borderRadius: '10px' }}
            onClick={async (e) => {
              const buttonTarg = e.currentTarget;
              await setTimeout(() => buttonTarg.blur(), 200);
              filterResults(
                document.getElementById('itemSearch').value,
                'toLines'
              );
              setCurrView('toLines');
            }}
          >
            View Incoming
          </Button>
        ) : currView === 'toLines' ? (
          <Button
            className='btn btn-warning'
            style={{ borderRadius: '10px' }}
            onClick={async (e) => {
              const buttonTarg = e.currentTarget;
              await setTimeout(() => buttonTarg.blur(), 200);
              filterResults(
                document.getElementById('itemSearch').value,
                'contractDetails'
              );
              setCurrView('contractDetails');
            }}
          >
            View Items
          </Button>
        ) : (
          ''
        )}
      </div>

      {/* FILTER BOX ROW */}
      <div className='input-group' style={{ padding: '0px 12px 6px 12px' }}>
        <input
          type='text'
          className='form-control'
          id='itemSearch'
          placeholder='Filter items...'
          style={{ border: '3px solid #8c99a9' }}
          onChange={(e) => {
            filterResults(e.currentTarget.value, currView);
          }}
        />

        <span className='input-group-btn' style={{ margin: '0', padding: '0' }}>
          <Button
            className='btn btn-warning'
            style={{
              borderRadius: '0px 5px 5px 0px',
              margin: '0',
              height: '100%',
            }}
            onClick={(e) => {
              const buttonTarg = e.currentTarget;
              setTimeout((e) => buttonTarg.blur(), 200);
              document.getElementById('itemSearch').value = '';
              setContractDetails(origContractDetails);
              setToLines(origToLines);
              e.currentTarget.blur();
            }}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </Button>
        </span>
      </div>
    </div>
  );
};

// DataControlsRow.whyDidYouRender = true;
export default DataControlsRow;
