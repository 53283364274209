import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'white',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#f5f5f5',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const TableCellHead = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#1a52a1',
  color: '#fff',
  textAlign: 'right',
  fontWeight: 'bold',
}));

const ContractDetails = (props) => {
  // console.log('RENDERING: Contract Details');
  const { contractDetails, origContractDetails, isLoading } = props.props;

  return (
    <div id='contractDetails'>
      <div
        id='tableView'
        style={{
          width: '100%',
          backgroundColor: '#1a52a1',
          color: '#fff',
          textAlign: 'center',
          fontWeight: 'bold',
          borderBottom: '2px dotted #869fb3',
        }}
      >
        Contract Items
        {contractDetails.length < origContractDetails.length
          ? ` (${contractDetails.length} of ${origContractDetails.length})`
          : ` (${contractDetails.length})`}
      </div>

      <TableContainer
        style={{
          overflowX: 'initial',
        }}
      >
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
              <TableCellHead
                style={{
                  textAlign: 'center',
                }}
              >
                SWA #
              </TableCellHead>
              <TableCellHead
                style={{
                  textAlign: 'left',
                }}
              >
                Your Part #
              </TableCellHead>
              <TableCellHead
                style={{
                  textAlign: 'left',
                }}
              >
                Description
              </TableCellHead>
              <TableCellHead>Shelf QTY</TableCellHead>
              <TableCellHead>ROP </TableCellHead>
              <TableCellHead>Max QTY</TableCellHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {contractDetails.map((row) => (
              <StyledTableRow
                key={row.swaItemNo}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell component='th' scope='row' align='center'>
                  {row.swaItemNo}
                </StyledTableCell>
                <StyledTableCell align='left'>{row.custPart}</StyledTableCell>
                <StyledTableCell align='left'>{row.itemDesc}</StyledTableCell>
                <StyledTableCell align='right'>
                  {row.shelfQty
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </StyledTableCell>
                <StyledTableCell align='right'>
                  {row.reorderQty
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </StyledTableCell>
                <StyledTableCell align='right'>
                  {row.maxQty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {origContractDetails.length === 0 && !isLoading ? (
        <div
          style={{
            textAlign: 'center',
            color: 'red',
            fontWeight: 'bold',
            background: '#FFF',
          }}
        >
          ERROR! <br /> Email admin@swaco.com to resolve. <br />
          Include your login email and VMI Contract # in your email.
        </div>
      ) : (
        ''
      )}
      <div
        style={{
          textAlign: 'center',
          color: 'red',
          fontWeight: 'bold',
          background: '#FFF',
        }}
      >
        {contractDetails.length === 0 ? 'No Results to Display' : ''}
      </div>
    </div>
  );
};

// ContractDetails.whyDidYouRender = true;
export default ContractDetails;
