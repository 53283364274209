import React from 'react';
import swLogo from './assets/img/sw_icon_transparent_small.png';
import Button from 'react-bootstrap/Button';
import Dialog from '@mui/material/Dialog';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Auth } from 'aws-amplify';

// import testFunction1, { testFunction2, testFunction3 } from './testFunction';

const Header = (props) => {
  // console.log('RENDERING: Header');

  const { isLoading, contractID, getData } = props.props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSignOut = async () => {
    console.log('handling sign out');

    await Auth.signOut();
    window.location.reload();
    setOpen(false);
  };

  return (
    <div
      id='header'
      style={{
        backgroundColor: '#1953a1',
        color: '#fff',
        borderBottom: '5px solid #fdc811',
        textAlign: 'left',
        verticalAlign: 'middle',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: '100%',
      }}
    >
      {/* SW LOGO */}
      <div id='swLogo' style={{ width: '157px' }}>
        <span
          style={{
            display: 'inline-block',
            height: '100%',
            verticalAlign: 'middle',
          }}
        ></span>
        <img
          style={{ verticalAlign: 'middle', padding: '6px 12px' }}
          alt='sw_logo'
          src={swLogo}
        ></img>
      </div>

      {/* CUSTOMER NAME */}
      <div
        id='contractNum'
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          {`${contractID}` || 'Loading...'}
        </div>
      </div>

      {/* BUTTONS */}
      <div
        id='btn-div'
        style={{
          padding: '6px 12px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          className='btn btn-warning'
          style={{
            borderRadius: '10px',

            boxShadow: isLoading ? '0px 0px 3px 3px #fdc811' : '',
          }}
          onClick={(e) => {
            const buttonTarg = e.currentTarget;
            setTimeout(() => buttonTarg.blur(), 200);
            getData('Header Refresh');
          }}
        >
          {isLoading ? (
            <FontAwesomeIcon icon={faSyncAlt} spin />
          ) : (
            <FontAwesomeIcon icon={faSyncAlt} />
          )}
        </Button>
        <Button
          className='btn btn-warning'
          style={{ marginRight: '5px' }}
          onClick={async (e) => {
            const buttonTarg = e.currentTarget;
            setTimeout(() => buttonTarg.blur(), 200);
            handleClickOpen();
          }}
        >
          <FontAwesomeIcon icon={faSignOutAlt} />
        </Button>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title' style={{ color: '#1a52a1' }}>
          Sign Out
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to sign out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outline-primary'
            style={{ borderColor: '#1a52a1', color: '#1a52a1' }}
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            style={{ backgroundColor: '#1a52a1', borderColor: '#1a52a1' }}
            onClick={handleSignOut}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

// Header.whyDidYouRender = true;
export default Header;
