// import './wdyr';
import React from 'react';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { Authenticator, Image, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Auth, Hub } from 'aws-amplify';
import { useState, useEffect } from 'react';
import Header from './Header';
import DataControlsRow from './DataControlsRow';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import ContractDetails from './ContractDetails';
import axios from 'axios';
import ToLines from './ToLines';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

Amplify.configure(awsconfig);

const components = {
  Header() {
    return (
      <View textAlign='center'>
        <Image
          alt='SWA Logo'
          src='https://vmi.swaco.com/assets/logo_white_transparent.png'
          padding='12px'
        />
      </View>
    );
  },
  SignUp: {
    Footer() {
      return (
        <div className='create-account-msg'>
          After creating your account, please email{' '}
          <a href='mailto:vmi.account@swaco.com?subject=VMI Portal - Access Request&body=The following email address has requested access to the VMI Portal:%0D%0A%0D%0AEmail Address:%0D%0AVMI Contract #:'>
            vmi.account@swaco.com
          </a>
          <br />
          Include your email address and VMI Contract #
        </div>
      );
    },
  },
  ConfirmSignUp: {
    Footer() {
      return (
        <div className='confirm-account-msg'>
          After creating your account, please email{' '}
          <a href='mailto:vmi.account@swaco.com?subject=VMI Portal - Access Request&body=The following email address has requested access to the VMI Portal:%0D%0A%0D%0AEmail Address:%0D%0AVMI Contract #:'>
            vmi.account@swaco.com
          </a>
          <br />
          Include your email address and VMI Contract #
        </div>
      );
    },
  },
  //   Header() {
  //     return <Heading>CUSTOM SIGN IN HEADER</Heading>;
  //   },
  // FormFields() {
  //   return (
  //     <>
  //       {/* Re-use default `Authenticator.SignUp.FormFields` */}
  //       <Authenticator.SignUp.FormFields />

  //       {/* Append & require Terms & Conditions field to sign up  */}
  //       <CheckboxField
  //         name='acknowledgement'
  //         value='yes'
  //         label='I agree with the Terms & Conditions'
  //       />
  //     </>
  //   );
  // },
};

const arraysAreEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (JSON.stringify(a[i]) !== JSON.stringify(b[i])) {
      return false;
    }
  }
  return true;
};

const App = () => {
  // const firstUpdate = useRef(true);
  const [contractDetails, setContractDetails] = useState([]);
  const [origContractDetails, setOrigContractDetails] = useState([]);
  const [toLines, setToLines] = useState([]);
  const [origToLines, setOrigToLines] = useState([]);
  const [currView, setCurrView] = useState('contractDetails');
  const [isLoading, setIsLoading] = useState(false);
  const [contractID, setContractID] = useState('');

  const showToast = () => {
    toast.success('Data has been refreshed...', {
      position: 'bottom-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: 'colored',
      style: { background: '#188754' },
    });
  };

  const listener = async (data) => {
    switch (data.payload.event) {
      case 'signIn':
        console.log('⇨Successful Login!');
        toast.info('Welcome!', {
          position: 'top-center',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          style: { background: '#1953a1' },
          progressStyle: { background: '#fdc811' },
        });
        await getData('Login Listener');
        break;
      case 'signUp':
        console.log('user signed up');
        break;
      case 'signOut':
        console.log('⇦ Logging Out');
        break;
      case 'signIn_failure':
        console.log('user sign in failed');
        break;
      case 'tokenRefresh':
        console.log('token refresh succeeded');
        break;
      case 'tokenRefresh_failure':
        console.log('token refresh failed');
        break;
      case 'configured':
        console.log('the Auth module is configured');
        break;
      default:
        console.log('Some other auth event occurred');
        break;
    }
  };
  Hub.listen('auth', listener);

  //  Used to Send NetSuite Request
  const sendNsRequest = async (apiReq, vmiKey) => {
    if (vmiKey) {
      return await axios
        .post(`https://vmi.swaco.com:443/nsApi`, {
          nsData: apiReq,
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {});
    } else {
      console.log('VMI KEY IS EMPTY. NOT RUNNING sendNsRequest');
      return;
    }
  };

  const getData = async (fromWhere) => {
    console.log('↳ getData called from ' + fromWhere);
    setIsLoading(true);
    let vmiKey = '';
    let vmiID = '';
    if (Amplify.Auth.user) {
      //  SET FROM USER AUTH

      vmiKey = Amplify.Auth.user.attributes['custom:vmi'];
      vmiID = Amplify.Auth.user.attributes['custom:vmiid'];
      // console.log('vmiKey: ' + Amplify.Auth.user.attributes['custom:vmi']);
      // console.log('vmiID: ' + Amplify.Auth.user.attributes['custom:vmiid']);
      // console.log(Amplify.Auth.user.attributes);
      // vmiID = 'VMI Number';
    } else {
      //  CALL AWS FOR USER AUTH ATTRIBUTES

      vmiKey = await getAttributes();
    }

    //  Proceed if VMI Key is provided and getAttributes did not return an error

    if (vmiKey) {
      //  Get Contract Details
      let apiReq = {
        action: 'getContractData',
        data: {
          vmiID: vmiID,
          vmiKey: vmiKey,
          from: fromWhere,
        },
      };

      await sendNsRequest(apiReq, vmiKey).then((res) => {
        if (res) {
          console.log('↻ Getting Contract Data');
          if (!arraysAreEqual(origContractDetails, res.contractDetails)) {
            setContractDetails(res.contractDetails);
            setOrigContractDetails(res.contractDetails);
          }

          if (!arraysAreEqual(origToLines, res.toLines)) {
            setToLines(res.toLines);
            setOrigToLines(res.toLines);
          }

          if (res.contractDetails.length > 0) {
            setContractID(`VMI${res.contractDetails[0].contractID}`);
            document.title = `VMI${res.contractDetails[0].contractID} - S.W. Anderson`;
          } else {
            setContractID('Click Refresh to Load Data...');
            document.title = 'S.W. Anderson VMI';
          }
        }
      });
    }
    setIsLoading(false);
    showToast();
  };

  const getAttributes = async () => {
    return await Auth.currentAuthenticatedUser()
      .then((resp) => {
        return resp.attributes['custom:vmi'];
      })
      .catch((error) => {
        return '';
      });
  };

  const isUserLoggedIn = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      return true;
    } catch {
      return false;
    }
  };

  const checkLogin = async () => {
    if ((await isUserLoggedIn()) && contractDetails.length === 0) {
      getData('Initial Render');
    }
  };

  useEffect(() => {
    console.log(
      'Authorize Users Only : Violators will be prosecuted to the fullest extent of the law.'
    );

    checkLogin();
  }, []);

  const dataControlRowProps = {
    currView,
    setCurrView,
    origContractDetails,
    setContractDetails,
    origToLines,
    setToLines,
  };

  const contractDetailsProps = {
    contractDetails,
    origContractDetails,
    isLoading,
  };

  const toLineProps = {
    toLines,
    origToLines,
  };

  const headerProps = {
    isLoading,
    contractID,
    getData,
  };

  return (
    <div id='app'>
      <Authenticator
        variation='modal'
        className='authStyle '
        components={components}
        // signUpAttributes={['custom:vmi']}
        // initialState='signUp'
      >
        {({ signOut, user }) => (
          <div id='appWrap'>
            <ToastContainer />

            <Header props={headerProps} />
            <DataControlsRow props={dataControlRowProps} />
            {currView === 'contractDetails' ? (
              <ContractDetails props={contractDetailsProps} />
            ) : currView === 'toLines' ? (
              <ToLines props={toLineProps} />
            ) : (
              ''
            )}
          </div>
        )}
      </Authenticator>
    </div>
  );
};
// App.whyDidYouRender = true;
export default App;
